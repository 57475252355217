import React, {useState, useEffect} from 'react'
import {Button} from 'react-bootstrap'
import './meLayout.css'

const ToClick = ({value, onClick, crud=2, selelcetd=0}) => {  
    const bstyle = { width:"100%", display: 'block', padding:'0.2rem' }  
    
    let bvariant = (crud===2) ? "outline-info" : "outline-warning"
    if(selelcetd>0 && selelcetd===value)
        bvariant = bvariant.replace("outline-","")

    return (
            <Button value={value} variant={bvariant} style={bstyle}
                onClick={onClick}
            >{value}
            </Button>
    )
}

const ToId = ({value, onClick, crud=2, selelcetd=0}) => {  
    return (
<td key={value} value={value}>
    <ToClick value={value} crud={crud} selelcetd={selelcetd} onClick={onClick} />      
</td>
 )
}

const ToDate = ({value}) => {
    let date = new Date(value)
    let text = date ? `${date.getYear()-100}-${date.getMonth()+1}-${date.getDate()}` : ""

    return (
        <td value={value} style={{ width:"6rem" }}>
            {text}
        </td>
    )
}


const Clickable = ({value, text, onClick}) => {
    return (
        <Button value={value} variant="outline-info" style={{ maxWidth:"100%", textAlign:"right" }}
            onClick={onClick}
        >{text}</Button>      
    )        
}

const ScrollTo = ({to, label, style}) => {

    const scrollTo = (to) => { 
        const element = document.getElementById(to); 
        if (element) { 
            element.scrollIntoView({ behavior: 'smooth' }); 
        } };
    
    return (
        <nav className="navbar" style={style}> <a href={`#${to}`} onClick={scrollTo}>{label??to}</a></nav>
    )
}

export {ScrollTo, ToClick, ToId, ToDate, Clickable}

