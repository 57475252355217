// Desc: French language config
import { contact, plan } from '../app';
import {enConfig} from './en';

export const frConfig = {
    ...enConfig.base,
    bismi:'Au nom de Dieu, le Tout Miséricordieux, le Très Miséricordieux.',
    salaam:'Salaam',
    header:{
        ...enConfig.header,
        signup : "Inscription",
        signin : "Connection",    
        signout : "Sortie",
        close: "Fermer",
        profile: "Profil",
        about : "À propos",
        contact : "Contact",
        article : "Articles",
        plan : "Plans",
        home : "Accueil",
        download : "Télécharger l'application",
        joinus : "Rejoignez-nous",
    },
    
    home:{
        ...enConfig.home,
        title : 'Synchroniser',
        about: 'Convertir du texte physique en format numérique',
        connected: 'Vous devez être inscrit'
    },

    plan:{
        ...enConfig.plan,
        title :'Abonnements & Plans',
        order :'Commander maintenant',
        extend : 'Obtenez plus de volume',
        Extend : 'Étendre le plan',
        credit : 'Il a du crédit'
    },

    profile:{
        ...enConfig.profile,
        salaam :'Salut, ',
        setting:'Paramètres',
        contact:'Contact',
        logout:'Déconnexion',
        site:'Site',
        profile:'Profil',
        home:'Accueil',
        contact:'Contact',
        order:'Commande',
        invoice:'Factures',
    },

    blog: {
        ...enConfig.blog,
        title :'Articles les plus intéressants',
    },

    invoice:{
        ...enConfig.invoice,
        title:['Facture Pro Forma', 'Facture'],
        order:[
            "Please confirm your order",
            "Une fois confirmé, vous recevrez les étapes à suivre pour le paiement",
            "Vous recevez la facture par email apres la reception du paiement",
        ],
        download:'Télécharger',
        pay:'Payer',
        action:'Commander',
        plan:'Plan',
    },

    contact:{
        ...enConfig.contact,
        title:'Contactez-nous',
        message:'Veuillez remplir le formulaire ci-dessous pour nous envoyer un email',
        confirm:'Confirmer le contact',
        content:'Veuillez remplir le code que nous vous avons envoyé par email pour le confirmer',
        recipient:"Merci de confirmer les informations du destinataire.",
        feedback : 'Votre message a été envoyé avec succès. Nous vous répondrons dans les plus brefs délais.',
        warning : "Vous devez confirmer votre e-mail avant de continuer"
    },
 
    getApp:{
        ...enConfig.getApp,
        name:'Téléchargez  Sync App',
        info:'Convertir du texte physique en format numérique',
        label:'Télécharger Sync App',
    }
}
