import React, { useState, useEffect } from "react"
import {Button,Form, Col} from 'react-bootstrap'
import {CountryControl} from '../Shared/CountryControl'


const SignPhone = ({signApi, onMessage}) => {
  const [submiting, Submiting] = useState(false); 
  const [action, Action] = useState('Start')
  const [valid, Valid] = useState(false)
  const [mode, Mode] = useState(0);

  useEffect(() => {
    if(valid) {
      if(signApi.isDataReady(2)) {
        onSubmit()
      }
    }
  }, [valid, mode])  

  useEffect(() => {
    if(mode===1) {
      if(signApi.isDataReady(2)) { // from Cockie
        const element = document.getElementById('Phone')
        if(element){
          if(signApi.isPhoneReady(element.value)) {
            Valid(true)
            Action('Next')
          }
        }
      }
    }
  }, [mode])    
  
  function onSubmit(event) {
    Submiting(true)
      signApi.data.from= 2

      signApi.POST(Mode)
      .then(()=>{
        if(signApi.data.mode>0) {
          document.cookie = `mePhone=${signApi.data.phone}`
          document.cookie = `meCountry=${signApi.data.country}`

          onMessage && onMessage(signApi.message)
          Submiting(false)
        }
      })
  }

  const onChange = (e) => {
    Valid(false)
    Action('Invalid Phone')
    if(signApi.isPhoneReady(e.target.value)) {
        Valid(true)
        Action('Next')        
    }
}

const onChangeCode = (e) => {
  signApi.data.code=e.target.value

  Valid(false)
  Action('Invalid Code')
  if(signApi.data.code.length>4) {
        Valid(true)
        Action('Sign In')
  }  
}

const onChangeCountry = (e) => {
  signApi.data.country=e.target?.value ?? 1
  onMessage && onMessage(`Country ${signApi.data.country}`)
}

const onKey = (e) => {
  if(e.key==='Enter') {
    e.preventDefault()
    if(valid) {
      onSubmit(e)
    }
  }
  //else if(e.key==='Escape') e.preventDefault()
}

  return (

<div className="outlet">

      { signApi.data.mode<3 
      ?
      <Form style={{width:"100%"}}>
          
           
           <Form.Group style={{justifyContent:"center", display: "flex", alignItems:"center"}}>  
                      
           { signApi.data.mode===1 ?
           <Col sm={8}>

             <CountryControl onChangeCountry={onChangeCountry} itemId={signApi.data.country} /> 

               <Form.Control key="SignPhone" id="Phone" className="SignInput"
               type="number"
               name="Phone"
               required
               placeholder="Phone number"
               defaultValue={signApi.data.phone}
               onChange={onChange}
               onKeyDown={onKey}
               />

              </Col>
              :
              <>
              <Form.Control key="SignCode" className="SignInput"
              style={{width:"50%", color:"darkred"}}
              type="text"
              name="Code"
              required
              placeholder="TCode"
              defaultValue=""
              onChange={onChangeCode}
              onKeyDown={onKey}
              />

              <a target="_blank" rel="noopener noreferrer" href="https://telegram.me/raisdigitalbot">
              <img className="Telegram" src="https://digitalservices.ma/images/telegram.png" alt="@RaisDigitalBot" style={{marginLeft:"40%", width:"5rem"}} />
              </a>
              </>
           }
           </Form.Group>

           <Form.Group id="SignIn" style={{justifyContent:"center", alignItems:"center", display: "flex", padding:"1rem"}}>
           <Button onClick={() => onSubmit()} size="lg" 
              style={ valid  && !submiting 
                ? {width:"33%", color:'white'} 
                : {width:"33%", backgroundColor:'darkgray', color:'gray', pointerEvents:'none', cursor:'not-allowed'}}
            >{action}</Button>

           </Form.Group>
      </Form>
      : 
      null
      }

    </div>
  )
}
export default SignPhone

