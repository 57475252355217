import React, { useState, useEffect } from "react"
import {Button,Form} from 'react-bootstrap'

const SignEmail = ({signApi, onMessage}) => {
  const [submiting, Submiting] = useState(false); 
  const [action, Action] = useState('Start'); 
  const [valid, Valid] = useState(false);
  const [mode, Mode] = useState(1);

  useEffect(() => {
    if(valid) {
      signApi.isDataReady(1)
      if(mode===2) {
        onSubmit()
      }
    }
  }, [mode, valid])  

  useEffect(() => {
    if(mode===1) {
      if(signApi.isDataReady(1)) { // from Cockie
        const element = document.getElementById('Email')
        if(element){
          //alert(element.value)            
          if(signApi.isEmailReady(element.value)) {
            element.focus()
            Valid(true)
            Action('Next')
          }
          //element.dispatchEvent(new Event('change')) //; alert(`Cockie ${element.value}`)
        }
      }
    }
  }, [mode])    

  const onSubmit = (e) => {
    if(valid) {
      Submiting(true)
      signApi.POST(Mode)
      .then(()=>{
        if(signApi.data.mode>0) {
          document.cookie = `meEmail=${signApi.data.email}`
          //alert(`meEmail=${signApi.data.email}`)
          onMessage && onMessage(signApi.message)
          Submiting(false)
          Valid(false)
        }
      })
    }
  }

  const onKey = (e) => {
    if(e.key==='Enter') {
      e.preventDefault()
      if(valid) {
        onSubmit(e)
      }
    }
    //else if(e.key==='Escape') e.preventDefault()
  }

  const onChange = (e) => {
    Valid(false)
    Action('Invalid Email')
    if(signApi.isEmailReady(e.target.value)) {
          Valid(true)
          Action('Next')
    }
  }

  const onChangeCode = (e) => {
    Valid(false)
    signApi.data.code=e.target.value
    Action('Invalid Code')
    if(signApi.data.code.length>4) {
          Valid(true)
          Action('Sign In')
    }  
  }

  return (
    <div className="outlet">
      
      { signApi.data.mode<3 ?
      <Form style={{width:"100%"}}>
           
           <Form.Group style={{justifyContent:"center", display: "flex", alignItems:"center"}}>  
                      
           { signApi.data.mode===1 ?
               <Form.Control key={"SignEmail"} id="Email"
               style={{display: "block", width:"70%", margin:"1rem",fontSize:"1.2rem", color:"darkblue"}}
               type="email"
               name="Email"
               required
               placeholder="Email"
               onKeyDown={onKey}
               defaultValue={signApi.data.email} 
               onChange={onChange}
               />
              :
              <Form.Control key={"SignCode"}
              style={{display: "block", width:"70%", margin:"1rem",fontSize:"1.2rem", color:"darkred"}}
              name="Code"
              required
              placeholder="Code5"
              onKeyDown={onKey}
              defaultValue=""
              onChange={onChangeCode}
              />
           }
           </Form.Group>

           <Form.Group style={{justifyContent:"center", alignItems:"center", display: "flex", padding:"1rem"}}>

           <Button onClick={() => onSubmit()} size="lg" 
              style={ valid  && !submiting 
                ? {width:"33%", color:'white'} 
                : {width:"33%", backgroundColor:'darkgray', color:'gray', pointerEvents:'none', cursor:'not-allowed'}}
            >{action}</Button>

           </Form.Group>
      </Form>
      : 
      null
      }
    </div>

  )
}
export default SignEmail
