import React, {useState, useEffect} from 'react'
import './Quote.css'
import {useNavigate } from 'react-router'
import jsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import {base, quote} from '../Config/app';
import ContactInfo from '../Profile/ContactInfo';
import {OptionControl} from '../Shared/InputControl';
import { addDays, toDate } from '../Shared/Formating';
import Api, {Read} from '../Shared/Api';
import {Ts} from '../Config/Lang/switcher';
import CompanyAddress from './Company';

const Invoice = ({lang, plan, account, paid=false}) => {
  
  const currency = "DH"
  const todate = addDays(15)
  const [confirm, Confirm] = useState({ed:false, email:'', name:'', account:0, id:0})
  const [approved, Approve] = useState(false)
  const [quantity,Quantity] = useState({count:0, price:0})
  const [selected, Select] = useState({Id:0, Name:'',Description:'', Price:0, options:[]}) 
  const [option , Option] = useState(1)
  const [order , Order] = useState({id:0, number:'25/001'})

  const navigate = useNavigate()

  const T = (value, key='invoice') => Ts(lang, key, value)
  const Bank = (value, key='bank') => Ts(lang, key, value)

  const titles = T('title')
  const messages = T('order')
  const company = Bank('Company')

  const [etat, Etat] = useState('') // to debug !

  useEffect(()=>{     
    window.scrollTo(0, 0)   
    if(selected.Id===0) {
      let request = `/plan/options/${Api.defaults.service}|${plan}` //;alert(request)
      Read(request, Select);

      Read(`/plan/order`, Order);
      //alert(account?.infos?.length);  console.log(account.infos);
    }
  },[selected.Id])


    const onSave = (e) => {
      SavePdf()
      return false;
    }

    const OnSubmit = (e) => {
      //e.preventDefault()
      e.target.disabled = true
      e.target.style.backgroundColor = 'gray'
      if(account?.connected) {
        PageToData()
        .then(page => {
          const data = {
            account: account.account,
            name: account.name,
            email: account.email,
            //service : selected.ServiceId, // is not needed when connected !
            category: "Invoice",
            content: page,
            plan: selected.Id,
            price: quantity.price
          }
          //console.log(data);alert(`Confirming ${page.length} ${data.email} ${data.id}`);
    
          Api.post(`/plan/order`, data)
          .then(response => {
            Etat(` Confirmed ${response.data.message}`)
            //delete data['content']
          })
          .catch(error => {
            //alert(JSON.stringify(error))
            Etat(` Confirmed ${JSON.stringify(error)} ?`); console.error(error)
          })
        })
      }
    }

    useEffect(()=>{     
      if(selected.Id>0) {  
        Quantity({count:option, price: selected.Price*option}) 
        //alert(selected.Price)
      }
    },[selected, option])
    
    const toPrice = (price, ratio) => {
      return (price*ratio).toFixed(2);
    }

    const onOption = (e) => {
      const object = e?.target
      if(object && object.value>0) {
        //alert(object.value)
        Option(object.value)
        e.stopPropagation()
      }
  }

    return (
selected.Id>0 ?
  <div className='Quote Invoice' id='container' >

  <header>
    <div className="header-text">
      <p>{titles[paid?1:0]}</p>
      <h3>n° {order.number}</h3>
    </div>
  </header>

  <div className="row">
    <CompanyAddress company={company} />

    <div className="col-md-6">
      {account?.connected ?
      <div className="Address">
        <h5>{account.name}</h5>
        <p>{account.email}</p>
      </div>
      :
      <ContactInfo service={selected.ServiceId} lang={lang} onConfirm={Confirm} /> 
      }
    </div>
      { etat.length>0 ? <p>{etat}</p> : null }
    </div>

  <table>
    <thead>
      <tr>
        { 
          quote.th.map(item=>
            <th style={{width:item.width}}>{item.name}</th>
          )
        }
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><h5>{selected.Name}</h5><p>{selected.Description}</p></td>
        <td>
          <OptionControl 
                    options={selected.options} 
                    title={`${quote.th[1].name}= ${quantity.count}`} 
                    onChange={e => {onOption(e)}}
                    defaultValue={quantity.count} 
                    readOnly={approved} 
                />


        </td>
        <td className="Price">{quantity.count<10 ? selected.Price : quantity.price}</td>
        <td className="Price">{quantity.price} {currency}</td>
      </tr>
    </tbody>
    <tfoot style={{backgroundColor:'#aaa'}}>
      <tr>
        <th colspan="3">Total HT</th>
        <td className="Price">{quantity.price} {currency}</td>
      </tr>
      <tr>
        <th colspan="3">TVA (20%)</th>
        <td className="Price">{toPrice(quantity.price,0.2)} {currency}</td>
      </tr>
      <tr>
        <th colspan="3">Total TTC</th>
        <td className="Price" style={{fontWeight:'bold'}}>{toPrice(quantity.price,1.2)} {currency}</td>
      </tr>
    </tfoot>
  </table>

{ account?.connected ?
  <div id="payment-container">
    <div id="payment-infos"  style={{marginTop:'2rem'}}>
      <p>{Bank('payment')}</p>
      <h6 style={{fontWeight:'bold'}}>{company} | {Bank('RIB')}</h6>
      <p>{Bank('Name')}</p>
    </div>
    <div id="save-button" data-html2canvas-ignore="true">
      <button onClick={(e) => OnSubmit(e)} className="Button" style={{margin: "1rem auto"}}>{messages[0]}</button>
      <h6>{messages[1]}</h6>
      <div className='payment row'>
        <div className='col-md-8'>
          <p>{messages[2]}</p>
        </div>
        <div className='col-md-4'>
          <button onClick={(e) => onSave(e)} className="Button">Save</button>
        </div>
      </div>
    </div>
  </div>
:
  <div className='error'>
    <p>{T('recipient','contact')}</p>        
    <button onClick={() => window.print()} className="Button">Print</button>
  </div>
}

</div>
:
<div className='Quote' id='container' style={{backgroundColor:quote.bc[0]}} >
      <h1>{T('unselected')}</h1>
</div>

    )
}

const PageToData = () => {
  return new Promise(function (resolve, reject) {

    const element=document.querySelector('#container')
    html2canvas(element, { 
      useCORS: true,
    })
    .then((canvas)=>{
      const data = canvas.toDataURL('image/jpeg'); //console.log('ToData', data); alert(data.length)
      resolve(data)
    })
    .catch((error) => {
      reject(error)
    })
  })
}

async function  SavePdf(){
  const pdf = new jsPdf("p", "mm", "a4");
  const size = pdf.internal.pageSize;

  const response = await fetch(quote.background, { mode: 'cors' });
  const blob = await response.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    pdf.addImage(reader.result, 'JPEG', 0, 0, size.width, size.height);
    //alert('Image loaded');
    PageToData()
    .then(page => {
      pdf.addImage(page, 'JPEG',0,0, size.width, size.height)
      pdf.save(`Invoice-${toDate()}.pdf`)
    })
  }
}

export default Invoice

