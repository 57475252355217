import React, {useState} from 'react'
import {Form} from 'react-bootstrap'

const InputControl = ({name,value,mode,type}) => {

    return (
        <div class="form-group form-row" >
            <label class="control-label col-sm-2" style={{width:"auto", textAlign:"right"}} for={"Input "+name}>{name}:</label>
            <div class="col-sm-10">
                <input type={type??"text"} class="form-control" id={name} 
                placeholder={name} 
                name={name} 
                defaultValue={value} 
                readOnly={(mode??0)===0} 
                onChange={(e) => {this.setState({value: value})}}
                />
            </div>
        </div>
        
    )
}

const TextControl = ({name,value,mode,type}) => {

    return (
        <Form.Group controlId={name} className='form-row'>
                <Form.Label className='col-sm-4'>{name} :</Form.Label>
                <Form.Control
                className='col-sm-8'
                type={type ?? 'text'}
                name={name}                
                placeholder={name}
                //value={value}
                defaultValue={value}                
                readOnly={mode===0}
                //  required
                />
            </Form.Group>        
    )
}

function  CheckControl({name="Checked", value=false, mode=0}) {
    const [checked, setChecked] = useState(value);
  
    return (
        <div class="form-group form-row">
      <label class="control-label col-sm-4" style={{width:"auto", textAlign:"right"}}>
          {name}: </label>
          <div class="col-sm-1">
        <input type="checkbox" name={name}
          defaultChecked={checked}
          onChange={() => setChecked(!checked)}
          readOnly={(mode??0)===0}
          class="form-control" 
        />
        </div>
      </div>
    )
}

const OptionControl = ({entity='Control', options, defaultValue, onChange, readOnly=false, title}) => {
    const id=`Option-${entity}` 
    const [value, Value] = useState(defaultValue??1)

    const onSelect=(e) =>{
        if(e?.target) {
            onChange && onChange(e)
            if(!readOnly && e.target.value>0 && value!==e.target.value) {
                Value(e.target.value)
            }
        }
    }
 
    return (
            <Form.Control as="select" id={id} key={id} className="text-right" 
                onChange={e => onSelect(e)} 
                defaultValue={value} 
                title={title}
                readOnly={readOnly} disabled={readOnly} 
                style={{fontSize:'1rem', height:'2rem',backgroundColor:'transparent'}}>
                {
                    options.map(item => 
                    <option id={`${id}-${item.Id}`} key={`${id}-${item.Id}`} 
                    value={item.Value}
                    selected={value===item.Value} 
                    style={{backgroundColor:'transparent'}}
                    >{item.Name}</option>
                    )
                }
            </Form.Control> 
        
    )
}

export default InputControl
export {TextControl, CheckControl, OptionControl}