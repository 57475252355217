import React,{useState, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import './Timers.css'
import { activityStyle, Duration } from '../Shared/Formating'
import {Clickable} from '../Shared/ViewControl'
import {Read} from '../Shared/Api'

const Timers = ({element, onElement}) => {
    const entity='Timer'
    const reported=6
    const [items, Items] = useState([]);
    const [selected, Select] = useState(element);
    const [loaded, Load] = useState(false);

    useEffect(() => {
        //alert(`loaded: ${items.length}`)
        if(!loaded) {
            //console.log('get timer: ', `/${entity}/${element}`)
            Read(`/report/${entity}/0`,Items, Load)
        }
        else {
            if(items.length>0){
                let id=items[0]?.Id ?? 0
                if(id>0) {
                    //console.log('reported: ', `Focused`, items[0].Focused)
                    items[0].Focused = parseInt(items[0].Focused)+reported*19
                    Select(id)
                }
            }
        }
    }, [loaded])

    const onClick = (id) => {
        Select(id)
    }
 
        return (
            
<Row className="timer-grid">

{ loaded ?
<React.Suspense fallback="Loading..."> 
<h6>{entity}(s)</h6>
    {    
        items.map(item=>
            <Timer key={`timer-${item.Id}`} item={item} selected={selected} onSelect={onClick} onElement={onElement} />
        )
    }
</React.Suspense>   
:
<h6 style={{color:'darkred'}}>{entity}(s) loading...</h6>
}
</Row>
        )
}


const Timer = ({item, selected, onSelect, onElement}) => {

    //console.log('<Timer...', item)
    let cstyle= {...activityStyle(item?.Activity)}

    useEffect(() => {

    }, [selected])

    const onClick = (item) => {
        //alert(item.Id)
        onSelect(item.Id)
    }

    return (
        <Col className="timer-cell" style={{...cstyle, border: (selected===item.Id ?'2px solid white':'')}} onClick={()=>onClick(item)}>
            <Row className="align-items-center">
            <Col sm={4} style={{fontSize: '1.5rem'}}>{Duration(item.Focused)}</Col>    
            <Col sm={4} >{item?.Activity}</Col>    
            <Col sm={4} ><Clickable value={item?.Element} text={item?.Element} onClick={onElement} /></Col>    
            </Row>
            <Row className="align-items-center" style={{fontSize: '0.8rem'}}>
            <Col sm={4} title='Date' >{item.Date}</Col>
            <Col sm={4}  title='Start'>{item.StartDate}</Col>
            <Col sm={4}  title='End'>{item.EndDate}</Col>
            </Row>
        </Col> 
    )

}

const Focused = (element, activity, onReported) => {
    const [duration, Duration] = useState(0)
    const [reported, Reported] = useState(3)

    useEffect(() => {
            let number=29
            const timer = setTimeout(function() {
                //console.log("duration reported : ", duration, reported)   
                Duration(duration + 1)
                if(reported===duration && element>0 && activity>0)
                {
                    const data = {
                        ActivityId: activity,
                        ElementId: element,
                        Focused: duration*number
                    }
                    //alert(`timer ${element} ${activity} ${data.Focused}`)
                    //Fetch(`/Timer`, data, 1)
                    Duration(0)

                    let random=Math.floor(Math.random() * 5)+1
                    Reported(random)
                    onReported && onReported(random)
                }
            }, number*1000)
        
            return () => { // this should work flawlessly besides some milliseconds lost here and there 
               clearTimeout(timer)
            }            
    }, [duration]);

}

export {Timers, Focused } 
