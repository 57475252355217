import React, { useState } from 'react';
import {NavLink} from 'react-router'; 
import './Profile.css';
import TableView from '../meTable/TableView';
import {ToNotify} from 'fast_inon';
import {Ts} from '../Config/Lang/switcher';

const Profile = ({lang, account}) => {
    const node = {entity:'Profile', id:1, parent:1}
    const [message, Message] = useState('')

    const T = (value, key='profile') => Ts(lang, key, value)

    const onCell = (item) => {
        //alert(`node=${item.Value}`)
    }

return (

<div className="Home" id="MainContainer">

{ account?.connected ?  
  <div className='CollapseTab'> 
    <TableView request={`/account/${node.entity}/${node.id}`} node={node} onChange={onCell} />
  </div>
: <h2>{T('login')}</h2> }

<ToNotify 
    message={message}
    onClose={()=>{Message('')}}
/>

</div>
        )
}

const ProfileMenu = ({lang, app, profile}) => {

  const T = (value, key='profile') => Ts(lang, key, value)

  const onLogout = (e) => {
      //alert('remove localStorage')
      let store = localStorage.getItem(app)
      if(store) {
        localStorage.removeItem(app)
        window.location.reload()
      }
  }

  return (
    <div id="ProfileItems" style={{visibility:profile}}>
      <ul>
        <li><NavLink to="/profile">{T('profile')}</NavLink></li>
        <li><NavLink to="/plan">{T('order')}</NavLink></li>
        <li><NavLink to="/home">{T('home')}</NavLink></li>
        <li><NavLink to="/site">{T('setting')}</NavLink> </li>
        <li><NavLink to="/home/#contact">{T('contact')}</NavLink></li>
        <li><a href="https://tech2us.ma/#contact" rel="noreferrer" target="_blank">Tech2us</a></li>
      </ul>
      <div className="divider"></div>
      <ul>
          <li style={{display:'block'}} onClick={onLogout} >Sign Out</li> 
      </ul>
    </div>
  )
}


export default Profile
export {ProfileMenu}
