import React, {useState, useEffect} from 'react'
import {Route, Routes, BrowserRouter} from 'react-router';
import './App.css';
import Home from './components/Home/Home';
import {Site} from './components/site/Site';
import {Drive} from './components/drive/Drive';
import {Worker} from './components/worker/Worker';
import {Detail} from './components/detail/Detail';
import {Step} from './components/step/Step';
import Header from './components/Home/Header';
import Footer from './components/Home/Footer';
import Global from './components/Home/Global';
import Landing from './components/Landing/Landing';
import Profile from './components/Profile/Profile';
import Plans from './components/Plan/Plans'
import Quote from './components/Invoicing/Quote';
import Invoice from './components/Invoicing/Invoice';
import Api, {Read} from './components/Shared/Api';


function App() {
  const app = Api.defaults.service
  const global = new Global()

  const service=2
  const [account, Account] = useState({connected:false, message:'', name:'', email:'',account:0})
  const [search, Search]  = useState("")
  const [plan, Plan] = useState("Extend")
  const [lang, Lang] = useState("en")
  const [items, Items]= useState([])
  
  useEffect(() => {
    const store = localStorage.getItem(app)
    if(store) {
        Account(JSON.parse(store))
        Read(`/account/Profile/1`, Items)
    }
  },[])

  useEffect(()=>{ 
    if(items?.length>0) {    
      //console.log(items); alert(items.length)   
      Account({...account, 
        account:items.find(item => item.Key === 'Id')?.Value,
        name:items.find(item => item.Key === 'Name')?.Value,
        email:items.find(item => item.Key === 'Email')?.Value
      })
    }
  },[items])


  useEffect(()=>{ 
    if(plan) {       
      //alert(plan)
    }
  },[plan])


  return (

    account?.connected ?
  <BrowserRouter>
  <Header lang={lang} app={app} connected={account?.connected} onSearch={Search} />
  <div className='container'>
      <Routes>    
        <Route path='/' element={ <Home app={app} lang={lang} connected={account?.connected} search={search} />} exact />
        <Route path='/site' element={ <Site global={global} search={search} />} />
        <Route path='/drive' element={ <Drive global={global} search={search} />} />
        <Route path='/worker' element={ <Worker global={global} search={search} />} />
        <Route path='/detail' element={ <Detail global={global} search={search} />} />
        <Route path='/step' element={ <Step global={global} />} />
        <Route path='/profile' element={ <Profile lang={lang} account={account} />} />
        <Route path='/home' element={ <Landing service={service} lang={lang} onLang={Lang} onPlan={Plan} />} />
        <Route path='/quote' element={ <Quote lang={lang} plan={plan} account={account} />} /> 
        <Route path='/invoice' element={ <Invoice lang={lang} plan={plan} account={account} />} /> 
        <Route path='/plan' element={ <Plans lang={lang} service={service} onPlan={Plan} account={account} /> } /> 
        
      </Routes>      
  </div>
  <Footer show={true}/>
</BrowserRouter>

:
  <BrowserRouter>
  <div className='container'>
    <Routes>    
      <Route path='/' element={ <Landing service={service} lang={lang} onLang={Lang} onPlan={Plan} />} exact />
      <Route path='/quote' element={ <Quote lang={lang} plan={plan} account={account} />} /> 
    </Routes>    
  </div>
  </BrowserRouter>

  )
}

export default App;
