const CompanyAddress = ({company}) => {

    return (

        <div className="col-md-6">
          {company.startsWith('R')?
          <div className="Address">
            <h5>{company}</h5>
            <p>Res Zomorroda 2, Rte d'Imouzzer</p>
            <p>30050 Fez</p>
            <p>Morocco</p>
          </div>
          :
          <div className="Address">
            <h5>{company}</h5>
            <p>Res Ma Chae Allah 5, Al Wifaq</p>
            <p>12000 Temara</p>
            <p>Morocco</p>
          </div>
        }
        </div>
    )
}

export default CompanyAddress;
