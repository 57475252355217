// Desc: Spanish language configuration
import { contact, getApp, plan } from '../app';
import {enConfig} from './en';

export const esConfig = {
    ...enConfig.base,
    bismi:'En el nombre de Dios, el Compasivo, el Misericordioso.',
    salaam:'Salaam',
    header:{
        signup : "Inscripción",
        signin : "Conexión",    
        signout : "Salida",
        close: "Cerrar",
        profile: "Perfil",
        about : "Acerca de",
        contact : "Contacto",
        article : "Artículos",
        plan : "Planes",
        home : "Inicio",
        download : "Descargar la aplicación",
    },
    
    home:{
        title : 'Sincronizar',
        about: 'Sincronizar servicio de local a nube',
        connected: 'Debe estar registrado'
    },

    profile:{
        salaam :'Hola, ',
        setting:'Ajustes',
        contact:'Contacto',
        logout:'Cerrar sesión',
        site:'Sitio',
        profile:'Perfil',
        home:'Casa',
        contact:'Contacto',
        order:'Orden',
        invoice:'Facturas',
    },

    plan:{
        ...enConfig.plan,
        title :'Suscripciones y Planes',
        order :'Ordenar ahora',
        extend : 'Obtenga más volumen',
        Extend : 'Extender Plan',
        credit : 'Tiene crédito'
    },
    
    blog: {
        ...enConfig.blog,
        title :'Artículos más interesantes',
    },

    invoice:{
        title:['Factura Pro Forma', 'Factura'],
        order:[
            "Please confirm your order",
            "Une fois confirmé, vous recevrez les étapes à suivre pour le paiement",
            "Vous recevez la facture par email apres la reception du paiement",
        ],
        download:'Descargar',
        pay:'Pagar',
        paid:'Pagado',
        unpaid:'No pagado',
        date:'Fecha',
        amount:'Cantidad',
        status:'Estado',
        action:'Acción',
        no:'Sin factura'
    },
    
    contact:{
        ...enConfig.contact,
    },

    getApp : {
        ...enConfig.getApp,
        name:'Sincronizar',
        info:'Sincronizar servicio de local a nube',
        label:'Download Sync App',

    }
}
