import styled from 'styled-components';
import {NavLink} from 'react-router'; 

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  background-color: transparent;
`;

const ActionButton = styled.a`
  background-color: #007bff; /* Blue button color */
  color: #fff; /* White button text */
  padding: 0.7rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  target: _blank;
  rel : noopener noreferrer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0069d9; /* Darker blue on hover */
  }
`;

const CallToAction = ({name='Sign in', id='connexion', label, onClick, to}) => {
  return (
    name.startsWith('Join') ?
    <ActionContainer  id={id}>
      <ActionButton href="mailto:contact@tech2us.ma?subject=I want to join you&body=Salaam, Thank you for accepting my membership request!">
      {label ?? name}
      </ActionButton>
      
    </ActionContainer>
    : to ?
    <ActionContainer  id={id}>
    <NavLink className="ActionLink" to={to}>
      {label ?? name}
    </NavLink>
  </ActionContainer>
  :
  <ActionContainer  id={id}>
  <ActionButton onClick={onClick} href={to}>
    {label ?? name}
  </ActionButton>
</ActionContainer>

  );
};

export default CallToAction;
