
import {enConfig} from './en';

export const arConfig = {
    ...enConfig.base,
    bismi:'بِسۡمِ ٱللهِ ٱلرَّحۡمَـٰنِ ٱلرَّحِيمِ',
    salaam:'ٱلسَّلَامُ عَلَيْكُمْ',
    header:{
        signup : "التسجيل",
        signin : "الدخول",    
        signout : "خروج",
        about : "عن",
        contact : "اتصل",
        article : "مقالات",
        plan : "الخطط",
        home : "الرئيسية",
        download : "تحميل التطبيق",
        joinus : "انضم إلينا",
    },
    
    home:{
        ...enConfig.home,
        title : 'تزامن',
        about: 'مزامنة الخدمة من المحلي إلى السحابة',
        connected: 'يجب أن تكون مسجلا'
    },
    
    profile:{
        ...enConfig.profile,
        salaam :'مرحبًا, ',
        setting:'الإعدادات',
        contact:'اتصل بنا',
        logout:'الخروج',
        site:'الموقع',
        profile:'الملف الشخصي',
        home:'الرئيسية',
        contact:'اتصل بنا',
        order:'الطلب',
        invoice:'الفواتير',
        login:'الدخول',
    },

    blog: {
        ...enConfig.blog,
        title :'أكثر المقالات إثارة للاهتمام',
    },

    
    plan:{
        ...enConfig.plan,
        title :'الاشتراكات والخطط',
        order:'اطلب الآن',
        extend : 'أحصل على مزيد من الرصيد',
        Extend : 'تمديد الخطة',
        credit : 'لديه رصيد'
    },

    invoice:{
        ...enConfig.invoice,
        title:['فاتورة مؤقتة', 'فاتورة'],
        action:'',
        download:'تحميل',
        pay:'دفع',
        paid:'مدفوع',
        unpaid:'غير مدفوع',
        date:'تاريخ',
        amount:'المبلغ',
        status:'الحالة',
        action:'العمل',
        no:'لا توجد فواتير',
        order:[
            "يرجى تأكيد طلبك",
            "عند التأكيد، ستتلقى الخطوات التي يجب اتباعها للدفع",
            "سوف تتلقى الفاتورة عبر البريد الإلكتروني بعد استلام الدفع",
        ],

    },

    quote: {
        ...enConfig.quote,
        title:'اقتباس شخصي',
        action : 'إرسال الطلب',
        order:[
            "يرجى تأكيد طلبك",
            "عند التأكيد، ستتلقى الخطوات التي يجب اتباعها للدفع",
            "شكرًا لك!"
        ],
        unselected :'? خطة غير محددة ?',
        valid: 'صالح حتى ',
    },

    contact:{
        ...enConfig.contact,
        title:'تواصل معنا',
        quote:'تأكيد الاتصال',
        message:'يرجى ملء النموذج أدناه لإرسال بريد إلكتروني إلينا',
        confirm:'تأكيد معلومات الاتصال',
        content:'يرجى ملء الكود الذي أرسلناه لك عبر البريد الإلكتروني لتأكيده',
        recipient:"يرجى تأكيد معلومات المستلم.",
        feedback : 'لقد تلقينا رسالتك، وسنقوم بالرد عليك في أقرب وقت ممكن',
        warning : 'يجب عليك تأكيد بريدك الإلكتروني قبل المتابعة',
    },

    getApp : {
        name : 'تحميل [Sync App]',
        info : 'تحميل التطبيق',
        label : 'تحميل التطبيق',
    },

    bank : {
        ...enConfig.bank,
        to : 'المستفيد',
        payment:'الدفع عن طريق التحويل البنكي'
    }

}

