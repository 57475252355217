import React, { Component } from 'react';
import {NavLink} from 'react-router'; 
import {Container} from 'react-bootstrap'; 

export class Navigation extends Component {

    render(){
        return (
this.props.connected &&
            <Container style={{marginTop: '1.2rem'}}>
                <NavLink className="d-inline p-3 navbar-custom" to="/site">Site</NavLink>
                <NavLink className="d-inline p-3 navbar-custom" to="/drive">Drive</NavLink>
                <NavLink className="d-inline p-3 navbar-custom" to="/worker">Worker</NavLink>
                <NavLink className="d-inline p-3 navbar-custom" to="/detail">Detail</NavLink>
                <NavLink className="d-inline p-3 navbar-custom" to="/step">Step</NavLink>                  
            </Container>
        )
    }
}