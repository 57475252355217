import React,{Component} from 'react'
import {Modal,Row,Col,Form,ButtonToolbar,Button} from 'react-bootstrap'
import {TextControl} from '../Shared/InputControl'
import HeaderControl from '../Shared/HeaderControl'
import FooterControl from '../Shared/FooterControl'
import SaveControl from '../Shared/SaveControl'
import {ToNotify} from 'fast_inon';
import {SiteControl} from '../site/SiteControl'
import {SiteModal} from '../site/SiteModal'

export class DriveModal extends Component {
    constructor(props){
        super(props)

        this.state = {snackbarmsg:'', Item:null, loading:false, modalShow:false}
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount(){
        if(this.props.show) {
            if(this.props.id>0) {
                this.setState({loading:true})
                this.props.global.GetItem(this.props.entity,this.props.id)
                .then(data =>  {
                        this.setState({Item:data, loading:false})

                        //console.log('ItemModal', this.state.Item)
                    }
                )
                .catch(error => console.log({ error }))
            }
        }
    }

    snackbarClose = (event) => {
        this.props.onHide()
    }

    handleSubmit(event){
        if(this.props.mode>0) {
            event.preventDefault();

    fetch(`${this.props.global.apiUrl+this.props.entity}`,{
        method:(this.props.mode===2)?'POST':'PUT',
        headers:this.props.global.headers,
        body:JSON.stringify({
            Id:(this.props.mode===2)?0:this.state.Item.Id,
            SiteId:event.target.SiteId.value,
            Name:event.target.Name.value,
            Path:event.target.Path.value,
            Pattern:event.target.Pattern.value,
            Ignore:event.target.Ignore.value        
        })
    })
    .then(response => response.json())
            .then(
                (result)=>{
                    this.setState({snackbarmsg:result.message})
                },
                (error)=>{
                    this.setState({snackbarmsg:error.message});
                }
            )
        }
    }

    modalClose =() => {
        this.setState({modalShow:false})
        this.forceUpdate()
    }
    
    render() {
        let { Item, loading } = this.state; 
        return(
            <div className="container">


<ToNotify 
message={this.state.snackbarmsg}
onClose={this.snackbarClose}
/>

            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
            centered
          >

<HeaderControl {...this.props} />

            <Modal.Body>

 <Row>
     <Col sm={8} >
     {
            (loading || (Item===null && this.props.mode<2)) ? <div>{this.props.entity} is loading...</div> :
         <Form onSubmit={this.handleSubmit}>

<SiteControl name='SiteId' request={this.props.global.apiUrl+'setting/sites'} headers={this.props.global.headers} id={Item ? Item.SiteId:1} readOnly={this.props.mode===0} label='Site'/> 
<TextControl name="Name" value={Item ? Item.Name:''} mode={this.props.mode} required />
<TextControl name="Path" value={Item ? Item.Path:''} mode={this.props.mode} required />
<TextControl name="Pattern" value={Item ? Item.Pattern:'*'} mode={this.props.mode} />
<TextControl name="Ignore" value={Item ? Item.Ignore:''} mode={this.props.mode} />

<SaveControl {...this.props} />


         </Form>
        }
     </Col>
     <Col>
     <ButtonToolbar style={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='primary' onClick={()=>this.setState({modalShow:true})}><i className="fa fa-plus"></i> Site</Button>
            </ButtonToolbar>
     </Col>
 </Row>

            </Modal.Body>

            <FooterControl {...this.props} />

          </Modal>

{this.state.modalShow ?
<SiteModal 
    global={this.props.global}
    entity={'sync/site'}
    id={0}
    mode={2}
    show={true}
    onHide={this.modalClose}
/>
: null}
</div>  
        )
    }
}
