import React, {useState, useEffect} from 'react'
import './Plan.css'
import {useNavigate } from 'react-router'
import styled from 'styled-components'
import {Read} from '../Shared/Api';
import {plan, quote} from '../Config/app';
import {Ts} from '../Config/Lang/switcher';
import { Button } from '../Shared/Styled';

const PlansContainer = styled.section`
    max-width: 1024px;
    margin: 1rem auto;
    padding: 2rem 0;
    text-align: center;
    background-color: #eee;
`;

const Plans = ({service, onPlan, lang, account}) => {
    const [items , Items] = useState([])
    const [loaded, Load] = useState(false)
    const navigate = useNavigate()

    const T = (value, key='plan') => Ts(lang, key, value)

    useEffect(()=>{        
  
      if(!loaded) {
        Read(`/plan/service/${service}`, Items, Load)
        //console.log(items)
      }
  },[service])

    const onOuote = (name) => {
        let i = items.findIndex(p=>p.Name===name)
        onPlan(name)
        //alert(`${name} - ${T('order')}`)
        const to = account?.connected ? '/invoice' : '/quote'
        navigate(to) 
    }

    const onExtend = () => {
        //if(!connected) 
        {
            alert(T('connected', 'home'))
        }
    }
  
    return (
loaded ? 
<PlansContainer  id="plans">
    <h3>{T('title')}</h3>

    <div className='Plans' >
        {items.filter(item => item.Name!=='Extend').map((item) => (
            <Plan 
                name={item.Name} 
                info={item.Description} 
                price={item.Price}
                icon={item.Icon ?? plan.icon} 
                label={T('order')}
                to={plan.link}
                onOuote={onOuote}
            />
        ))}
    </div>

    {items.filter(item => item.Name==='Extend').map((item) => (
    <Extend 
        name={item.Name} 
        info={item.Description} 
        price={item.Price}
        icon={item.Icon ?? plan.icon} 
        label={T('extend')}
        to={plan.link}
        onClick={onOuote}
    />
    ))}

</PlansContainer>
:
null

    )

}

const Extend = ({name, icon, info, price, label, onClick, connected=false}) => {

    const image = icon.startsWith("https") ? icon : "images/"+icon

    return (
        <div className="row Plan PlanExtend" key={`Plan-${name}`} disabled={!connected}>
            <div className="col-md-3">
                <img src={image} alt={name+": "+info}  />
            </div>
            <div className="col-md-5">
                <h2 style={{fontWeight: 'bold'}}>{name}</h2>
                <p><b>{price}</b> {'DH HT'}</p>
                <h6 className="PlanInfo">{info}</h6>
                </div>
              <div className="col-md-4">
                <Button onClick={()=>onClick()} style={{height:'6rem'}}>{label}</Button>
               </div>
        </div>


    )
}


const Plan = ({name, icon, info, price, label, to, style, onOuote}) => {

    const [hover, Hover] = useState(false)

    const bc = quote.bc[name==='Free' ? 1: name==='Personal' ? 2 : 3]
    const image = icon.startsWith("https") ? icon : "images/"+icon

    const onHover = (e) => {
        e.target.style.background = bc
        Hover(true)
    }

    const onLeave = (e) => {
        e.target.style.background = 'transparent'
        Hover(false)
    }

    useEffect(()=>{        
        if(hover) {

        }
    },[hover])

    return (
        <div className='Plan' style={style} key={`Plan-${name}`}
            onMouseEnter={(e) => onHover(e)}
            onMouseLeave={(e) => onLeave(e)}>

            <div className="center">
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <img src={image} alt={name+": "+info} style={{ backgroundColor: bc }} />
                </a>
            </div>
            
            <div className="PlanName">
                <h2 style={{fontWeight: 'bold'}}>{name}</h2>
                <p><b>{price}</b> {'DH HT/mois'}</p>
                <h6 className="PlanInfo">{info}</h6>
                <br/>
                <Button onClick={()=>onOuote(name)}>{label}</Button>
                <br/>
            </div>
        </div>
    )
}

export default Plans
