
import React, { useState, useEffect } from 'react';
import './Contact.css';
import {contact} from '../Config/app'
import {Fetch} from '../Shared/Api';
import {Ts} from '../Config/Lang/switcher';

const Contact = ({service, lang}) => {
    const [etat, Etat] = useState('') // to debug !
    const [info, Info] = useState({mode:1, name:'', email:'', message:'', code:'', service})
    const [confirmed, Confirm] = useState(false) // managed by info.mode

    const T = (value, key='contact') => Ts(lang, key, value)
    
    const onMode = (add=1) => {
        info.mode += add
        Info({...info})
    }

  const onSend = (e) => {
    const element = document.getElementById("contact") 
    Fetch('/service/message', info, element) //e.target)
    .then(res => {
        //alert(res.status)
        onMode()
    })
    .catch(err => {
        //alert(err.status)
        if(err.status===208) {
            Etat(T('feedback'))
            onMode()
            document.cookie = `meContact=${info.name}|${info.email}`
        }
    })
}

const onChange = (e) => {    
    info[e.target.name]=e.target.value
    Info({...info})
  }

  // to debug
  useEffect(() => {
    //Etat(`info=${info.service} n=${info.name} e=${info.email} m=${info.message} c=${info.code} m=${info.mode}`)
  }, [info]);

  return (
    <div id="contact">
        <div className="row container">
            <div className="col-md-8">
                { info.mode===1 ?
                <div className="row">
                <h2>{T('title')}</h2><p>{info.mode<2?T('message'):T('feedback')}</p>
                <div className="row" style={{margin:'1rem 0'}}>
                    <div className="col-md-6">
                        <div className="form-group">
                            <input name="name" onChange={onChange} type="text" id="name" className="form-control" placeholder="Full Name" required />
                        </div>
                    </div>
                    <div className="col-md-6">
                    <div className="form-group">
                    <input name="email" onChange={onChange} type="email" id="email" className="form-control" placeholder="Email" required />
                    </div>
                    </div>
                    </div>

                    <div className="form-group">
                        <textarea name="message" onChange={onChange} id="message" className="form-control" rows="4" placeholder="Message" required ></textarea>
                    </div>
                    <button onClick={(e) => onSend(e)} className="btn btn-custom">Send Message</button>
                </div>
                :
                info.mode===2 ? 
                <div className="row">
                    <h2>{T('title')}</h2><p>{T('content')}</p>
                    <div className="row">
                        <div className="col-md-6 inputCode">
                            <input name="code" onChange={onChange} placeholder="------" type="text" id="code" className="form-control" required="" />
                        </div>
                    </div>
                    <button onClick={(e) => onSend(e)} className="btn btn-custom">Send Code</button>
                </div>
                :
                <div className="row">
                    <h2>Sending</h2><p>{T('feedback')}</p>
                </div>
                }
            </div>
                
            <div className="col-md-3 col-md-offset-1 contact-info">
                <div className="contact-item"><h3>Contact Info</h3><p>Ma Chae Allah, Al Wifaq, Temara 12000</p></div>
                <div className="contact-item"><p>+212 660 800 630</p></div>
                <div className="contact-item"><p>contact@tech2us.ma</p></div>
            </div>

            <div className="col-md-12">
                <ul className="row center">
                <li className="col-md-1"><a href="https://www.linkedin.com/company/tech2us" target="_blank" rel="noopener noreferrer">
                    <img src="https://2us.ma/images/linkedin.png" alt="linkedin" title="linkedin" />
                </a></li>
                <li className="col-md-1"><a href="https://www.facebook.com/tech2us.ma" target="_blank" rel="noopener noreferrer">
                    <img src="https://2us.ma/images/facebook.png" alt="facebook" title="facebook" />
                </a></li>
                <li className="col-md-1"><a href="https://www.youtube.com/@tech.2us" target="_blank" rel="noopener noreferrer">
                    <img src="https://2us.ma/images/youtube.png" alt="youtube" title="youtube" />
                </a></li>
                </ul>
                {  etat.length>0 ? <p>{etat}</p> : null }
            </div>
    </div>
    </div>
  );
};

export default Contact;

/*
const onChange = (e) => {    
    const name=e.target.name
    let value=e.target.value
    switch(name){
        case 'name':{
            Info({...info, name:value})
            break;
        }
        case 'email':{
            Info({...info, email:value})
            break;
        }
        case 'message':{
            Info({...info, message:value})
            break;
        }
    }
  }
*/