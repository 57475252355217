import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Read } from '../Shared/Api';

export const DriveControl = ({request,name,id,readOnly, label}) => {
    const [items, Items]= useState([])
    const [loaded, Load] = useState(false)

    useEffect(() => {
        if(!loaded) {
            Read(request, Items, Load)
        }
    }, [loaded])

    return (
        loaded ? 
    <Form.Group className='form-row'>
        <Form.Label className='col-sm-4' style={{ display: 'flex' }}>{label} :
        </Form.Label>
        <Form.Control className='col-sm-8' as="select" name={name} defaultValue={id} readOnly={readOnly} >
            {
                items.map(item => 
                <option key={item.Id} value={item.Id}>{`${item.Name} (${item.Id} [${item.Site.Name}])`}</option>
            )}
        </Form.Control>
    </Form.Group>          
        :
        <div>{`${name}s is loading...`}</div>
    )      
}
