// Hero.js (example)
import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring'; // Animation library
import styled from 'styled-components';
import {Ts} from '../Config/Lang/switcher';

const About = ({service, lang}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
//    const trigger = useScrollTrigger({ onEnter: () => setIsScrolled(true), onLeave: () => setIsScrolled(false) });
//    return () => trigger.cleanup();
  }, []);

  const heroAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: isScrolled ? 1 : 0 },
    config: { duration: 1000 },
  });

  return (
    <AboutContainer style={heroAnimation} id="about">
      <h1 className='brillant'>{Ts(lang,'home','about')}</h1>
    </AboutContainer>
  );
};


const AboutContainer = styled.div`
  max-width: 1024px;
  margin: auto;
  background-image: url('https://sync.2us.ma/images/Sync-4.jpeg');
  background-size: cover; /* contain; */
  background-position: center;
  height: 50vh;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  align-items: center;
  color: #fff;
`;

export default About;

