// Desc: Chinese language configuration
import { contact, plan } from '../app';
import {enConfig} from './en';

export const cnConfig = {
    ...enConfig.base,
    bismi:'以真主的名义，至仁至',
    salaam:'愿你平安',
    header:{
        signup : "注册",
        signin : "登录",    
        signout : "退出",
        close: "关闭",
        profile: "个人资料",
        about : "关于",
        contact : "联系",
        article : "文章",
        plan : "计划",
        home : "主页",
        download : "下载应用",
    },
    
    home:{
        title : '同步',
        about: '将本地服务同步到云端',
        connected: '您必须注册'
    },

    profile:{
        salaam :'你好, ',
        setting:'设置',
        contact:'联系',
        logout:'登出',
        site:'网站',
        profile:'个人资料',
        home:'主页',
        contact:'联系',
        order:'订单',
        invoice:'发票',
    },

    plan:{
        ...enConfig.plan,
        title :'订阅与计划',
        order :'立即订购',
        extend : '获得更多容量',
        Extend : '扩展计划',
        credit : '他有信用'
    },

    blog: {
        ...enConfig.blog,
        title :'最有趣的文章',
    },

    invoice:{
        title:['形式发票', '发票'],
        order:'订单',
        download:'下载',
        pay:'支付',
        paid:'已支付',
        unpaid:'未支付',
        date:'日期',
        amount:'金额',
        status:'状态',
        action:'行动',
        no:'没有发票'
    },

    contact:{
        ...enConfig.contact,
        title:'联系我们',   
    },

    getApp : {
        ...enConfig.getApp,
        name : '同步应用',
        info : '将本地服务同步到云端',
        label : '输入您的电子邮件',
    },


}
