import { a } from 'react-spring'
import {base, plan as _plan, bank as _bank} from '../app'

export const enConfig = {
    ...base,
    bismi : 'In the name of God, the Most Gracious, the Most Merciful',
    salaam :'Salaam',
    header:{
        joinus : "Join us",
        signin : "Sign in",
        signup : "Register",
        about : "About",
        contact : "Contact",
        article : "Articles",    
        plan : "Plans",
        signout: "Exit",
        download: "Get App",
    },
    
    home:{
        title:'Synchronize',
        about:'Synchronize Service from Local to Cloud',
        connected: 'You must be registered'
    },

    profile:{
        salaam :'Hi, ',
        setting:'Settings',
        contact:'Contact',
        logout:'Logout',
        site:'Site',
        profile:'Profile',
        home:'Home',
        contact:'Contact',
        order:'Order',
        invoice:'Invoices',
        login:'Login',
    },
    
    blog:{
        title :'Most Interesting Articles',
        read:'Read more...'
    },
            
    plan:{
        ..._plan,
        title :'Subscription & Plans',
        order :'Order Now',
        extend : 'Get more volume',
        Extend : 'Extend Plan',
        credit : 'He has credit'
    },

    invoice:{
        title:['Pro Forma Invoice', 'Invoice'],
        download:'Download',
        pay:'Pay',
        paid:'Paid',
        unpaid:'Unpaid',
        date:'Date',
        amount:'Amount',
        status:'Status',
        action:'Order',
        order:[
            "Please confirm your order",
            "When confirmed you will receive the steps to follow for payment",
            "You will receive the invoice by email after receipt of payment",
        ],
        no:'No invoice',
        plan:'Plan'
    },

    quote: {
        title:'Personalized Quote',
        action : 'Submit Order',
        order:[
            "Please confirm your order",
            "When confirmed you will receive the steps to follow for payment",
            "Thank you!"
        ],
        unselected :'? Unselected Plan ?',
        valid: 'Valid until ',
    },
    
    contact:{
        title:'Get In Touch',
        message:'Please fill out the form below to send us an email',
        confirm:'Contact Confirming',
        content:'Please fill out the code we sent you by email to confirm it',
        recipient:"Please confirm the recipient's information.",
        feedback : 'We have received your message, we will get back to you as soon as possible',
        warning : "Need to confirm your email before proceeding"
    },
 
    getApp : {
        name : 'Download Sync App',
        info : 'Download Sync App',
        label : 'Download Sync App'
    },

    bank : {
        ..._bank,
        to : 'Beneficiary',
        payment:'Payment by bank transfer',
    }
}
