import React from 'react';
import {enConfig} from './en';
import {arConfig} from './ar';
import {frConfig} from './fr';
import {esConfig} from './es';
import {cnConfig} from './cn';

const translations = {
  ar: arConfig,
  en: enConfig,
  fr: frConfig,
  es: esConfig,
  cn: cnConfig
}

export const LangSwitcher = ({lang, onLang}) => {
  //const { lang, setLang } = useContext(LangContext);

  const OnChange = (event) => {
    let lg = event.target.value
    onLang(lg);
    //alert(lg)
  };

  return (
    
    <select onChange={OnChange}  id="SwitchLang" value={lang} style={{width:'auto', margin:'1rem 0', float:'right'}}>
      <option alt="Arabic" value="ar">العربية</option>
      <option alt="English" value="en">English</option>    
      <option alt="Frensh" value="fr">Français</option>
      <option alt="Spanish" value="es">Español</option>
      <option alt="Chinese" value="cn">汉语</option>
    </select>
    
  );
};


export const Ts = (lang,key,item=null) => {
  //const { lang, setLang } = useContext(LangContext)
  //alert(lang)
  if(item===null) 
    return translations[lang][key] || key;
  else
    return translations[lang][key][item] || key+"-"+item
};

/*
  <option alt="Frensh" value="fr">Français</option>
  <option alt="Spanish" value="es">Español</option>
  <option alt="Chinese" value="cn">汉语</option>
*/